<template>
  <div :class="$style.containerForm">
    <div class="card" :class="$style.cardSection">
      <a-row class="mb-4">
        <a-col :span="24" class="d-flex justify-content-center">
          <div class="text-center font-size-24" :class="$style.colorPrimary">
            <strong> {{ $t('authForm.createAccount') }} </strong>
          </div>
        </a-col>
      </a-row>

      <a-form :form="form" @submit="handleSubmit">
        <a-row>
          <a-col :xs="24" :sm="24" :md="24">
            <a-form-item>
              <label :class="$style.labelInput">{{ $t('authForm.firstName') }}</label>
              <a-input size="large" :placeholder="$t('authForm.placeholderFirstName')"
                v-decorator="['first_name', { initialValue: userData.first_name, rules: [{ required: true, message: $t('authForm.requiredFirstName') }] }]" />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :sm="24" :md="24">
            <a-form-item>
              <label :class="$style.labelInput">{{ $t('authForm.lastName') }}</label>
              <a-input size="large" :placeholder="$t('authForm.placeholderLastName')"
                v-decorator="['last_name', { initialValue: userData.last_name, rules: [{ required: true, message: $t('authForm.requiredLastName') }] }]" />
            </a-form-item>
          </a-col>
          <a-col v-if="verificationType.length >= 2" :xs="24" :sm="24" :md="24">
            <a-alert :message="$t('authForm.mobileNumberOrEmail')" type="info" show-icon />
            <div style="margin: auto">
              <div class="switch-user-type">
                <div v-bind:class="[verifyType === 'phone' ? 'active' : '']" @click="handleSwitch('phone')">
                  {{ $t('authForm.phone_number') }}
                </div>
                <div v-bind:class="[verifyType === 'email' ? 'active' : '']" @click="handleSwitch('email')">
                  Email
                </div>
              </div>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" v-if="verifyType === 'email'">
            <a-form-item>
              <label :class="$style.labelInput">{{ $t('authForm.email') }}</label>
              <a-input type="email" size="large" :placeholder="$t('authForm.placeholderEmail')" v-decorator="['email', {
                initialValue: userData.email,
                rules: [{
                  required: true,
                  validator: (role, value, callback) => {
                    const regex = /\S+@\S+\.\S+/
                    if (!regex.test(value)) {
                      callback('Email invalid')
                    } else if (!value) {
                      callback($t('authForm.requiredEmail'))
                    } else {
                      callback()
                    }
                  },
                  message: $t('authForm.requiredEmail'),
                }]
              }]" />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :sm="24" :md="24" v-if="verifyType === 'phone'">
            <a-form-item>
              <label :class="$style.labelInput">{{ $t('authForm.phoneNumber') }}</label>
              <a-input size="large" v-decorator="[
                'phone',
                {
                  initialValue: userData.phone,
                  rules: [{
                    required: true,
                    message: $t('authForm.requiredPhoneNumber'),
                    pattern: new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, '')
                  }],
                },
              ]" :placeholder="$t('authForm.placeholderPhoneNumber')" />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :sm="24" :md="24">
            <a-form-item>
              <label :class="$style.labelInput">{{ $t('authForm.password') }}</label>
              <a-input-password size="large" type="password" :placeholder="$t('authForm.placeholderPassword')"
                v-decorator="[
                  'password',
                  {
                    rules: [{
                      required: true,
                      min: 8,
                      message: $t('authForm.minRequiredPassword'),
                      pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@*()?<>~`,./|{}#$%^&+_=-]).{8,}$/g, ''),
                    }],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :sm="24" :md="24">
            <a-form-item>
              <label :class="$style.labelInput">{{ $t('authForm.confirmPassword') }}</label>
              <a-input-password size="large" type="password" :placeholder="$t('authForm.placeholderConfirmPassword')"
                v-decorator="[
                  'confirmpassword',
                  {
                    rules: [
                      {
                        validator: (rule, value, callback) => value && value !== form.getFieldValue('password') ? callback($t('form.confirmPasswordNotMatch')) : callback(),
                        message: $t('form.confirmPasswordNotMatch'),
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <P class="text-disclaimer">{{ $t('authForm.disclaimer') }}</P>
          </a-col>

          <a-col :xs="24" :sm="24" :md="24">
            <a-button htmlType="submit" size="large" class="btn-submit btn-primary text-center w-100" :loading="loading">
              <strong> {{ $t('authForm.register') }} </strong>
            </a-button>
          </a-col>
        </a-row>
      </a-form>

      <a-row class="mt-4">
        <a-col :span="24" class="d-flex justify-content-center flex-column">
          <!-- <div class="text-center">
            <span class="mr-1"> {{ $t('authForm.toc') }} </span>
            <a href="javascript: void(0);" class="kit__utils__link">Terms of Service</a> {{ $t('authForm.and') }}
            <a href="javascript: void(0);" class="kit__utils__link">Privacy Policy</a>
          </div> -->
          <!-- <div class="text-center pt-2 mb-auto">
            <span class="mr-2"> {{ $t('authForm.alreadyHaveAccount') }} </span>
            <router-link to="/auth/login" class="kit__utils__link font-size-16"> {{ $t('authForm.signIn') }} </router-link>
          </div> -->
        </a-col>
      </a-row>

      <div>
        <a-modal v-model="modalOTP" :footer="null" :centered="true" :closable="false" :maskClosable="false">
          <div :class="$style.modalOtp">
            <img :src="urlImage" class="logo" :class="$style.logoVendorOtp" />
            <h5 :class="$style.titleInfoOtp">
              {{ verifyType === 'phone' ? $t('verify.otpSubTitlePhone') : $t('verify.otpSubTitleEmail') }}
            </h5>
            <div class="mt-2">
              <div class="mb-2 mt-5">
                <div class="d-flex justify-content-center">
                  <v-otp-input ref="otpInput" :input-classes="$style.otpInput" separator="" :num-inputs="6"
                    :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChangeOTP" />
                </div>
              </div>
              <a-button type="primary" size="large" class="text-center btn-verify" @click.prevent="handleVerify"
                :disabled="otp.length < 6">
                <strong>{{ $t('verify.otpButton') }}</strong>
              </a-button>

              <p :class="$style.subtitleOtp">
                {{ $t('verify.otpWaitingText') }}
              </p>
              <p :class="$style.timerOTP">
                {{ time }}
              </p>
              <a-button type="link" :disabled="countDown > 0" @click.prevent="handleResend">
                {{ $t('verify.otpResend') }}
              </a-button>
            </div>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>

import Cookies from 'js-cookie'
import { mapState } from 'vuex'

export default {
  name: 'CuiRegister',
  computed: {
    ...mapState(['app']),
    loading() {
      return this.$store.state.user.loading
    },
    isVendorKino() {
      return this.$store.state.app.vendor_name && this.$store.state.app.vendor_name.toLowerCase() === 'kino'
    },
    urlImage() {
      return this.isVendorKino ? 'https://ik.imagekit.io/powerbiz/img/kino/master_copy.png?updatedAt=1640933165040' : this.$store.state.app.logo
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      modalOTP: false,
      formValue: {},
      email: '',
      userId: null,
      userData: {},
      verifyType: 'email',
      countDown: 300,
      time: '',
      otp: '',
      typeOTP: 'SELT_REGISTER',
      isChangeLoginReq: false,
      verificationType: [],
    }
  },
  watch: {
    '$store.state.meta.data': {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          value.map((item) => {
            if (item.meta_name === 'otp') {
              this.verificationType = JSON.parse(String(item.meta_value))
              if (this.verificationType.length === 1 && this.verificationType.includes('email')) {
                this.verifyType = 'email'
              } else if (this.verificationType.length === 1 && this.verificationType.includes('wa')) {
                this.verifyType = 'phone'
              }
            }
          })
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch('meta/CONTACT')
    // const urlSearchParams = new URLSearchParams(window.location.search)
    // const params = Object.fromEntries(urlSearchParams.entries())
    // if (params && !!params.change_login_req) {
    //   this.getDetailUser(params.id)
    // }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (this.form.getFieldValue('password') !== this.form.getFieldValue('confirmpassword')) {
          return this.$notification.error({
            message: this.$t('authForm.passwrdNotmatch'),
          })
        }

        if (!err) {
          this.formValue = values
          this.email = this.form.getFieldValue('email')
          this.handleRegister()
          return true
        }
      })
    },
    handleRegister() {
      const verifyType = this.verifyType
      let payload = {
        formValue: {
          ...this.formValue,
        },
        verifyType,
        userId: this.userData.user_id,
        type: this.userData.change_login_req ? 'CHANGE_LOGIN_REQUEST' : 'SELF_REGISTER',
      }
      this.typeOTP = this.userData.change_login_req ? 'CHANGE_LOGIN_REQUEST' : 'SELF_REGISTER'
      this.$store.dispatch('user/REGISTER', payload)
        .then(({ data }) => {
          payload = {
            ...payload,
            user_id: data.id,
            type: this.typeOTP,
          }
          this.userId = data.id
          this.$store.dispatch('user/SENTOTP', payload)
            .then(() => {
              this.$notification.success({
                message: this.$t('authForm.registerSuccess', { type: verifyType, payload: this.formValue.email || this.formValue.phone }),
              })
              this.modalOTP = true
              this.countDown = 300
            })
            .catch(err => {
              this.$notification.error({
                message: this.$t('authForm.registerFailed'),
                description: err.response?.data?.message || err.message || 'Internval Server Error',
              })
            })
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    handleVerify() {
      const payload = {
        verifyType: this.verifyType,
        user_id: this.userId,
        otp: this.otp,
      }
      this.$store.dispatch('user/VERIFYOTP', payload)
        .then(({ link }) => {
          Cookies.set('password-register', this.formValue.password)
          this.$router.push(`/reg-conf/${link}`)
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    handleResend() {
      const payload = {
        verifyType: this.verifyType,
        user_id: this.userId,
        type: this.typeOTP,
      }
      this.$store.dispatch('user/SENTOTP', payload)
        .then(() => {
          this.countDownTimer()
          this.countDown = 300
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    handleGetQueryParam() {
      const { userId } = this.$route.query
      this.userId = userId
    },
    handleSwitch(verifyType) {
      this.verifyType = verifyType
    },
    handleOnChangeOTP(value) {
      this.otp = value
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1

          let minutes = Math.floor(this.countDown / 60)
          minutes = minutes < 10 ? `0${minutes}` : minutes
          let seconds = this.countDown - minutes * 60
          seconds = seconds < 10 ? `0${seconds}` : seconds
          this.time = `${minutes} : ${seconds}`

          this.countDownTimer()
        }, 1000)
      }
    },
  },
  created: function () {
    this.countDownTimer()
    const user_data = Cookies.get('user_data') ? JSON.parse(Cookies.get('user_data')) : {}
    if (user_data.change_login_req) {
      this.userData = user_data
      this.verifyType = user_data.email ? 'email' : 'phone'
    }
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
<style lang="scss" scoped>
.switch-user-type {
  margin: 30px auto;
  width: 80%;
  border: 1px solid #000000;
  border-radius: 100px;
  padding: 8px 12px;
  display: flex;

  div {
    width: 100%;
    border: unset;
    background: unset;
    padding: 8px;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    background: #000000;
    color: white;
  }
}

.text-disclaimer {
  font-weight: 200;
  font-size: 12px;
  line-height: 16px;
  color: #7D7D7D;

  @media (min-width: 700px) {
    font-weight: 400;
  }
}

.btn-submit {
  background-color: var(--biz-brand-button);
  color: #ffffff;
  border-color: var(--biz-brand-button);
  font-size: 14px;

  &:disabled {
    background-color: var(--biz-primary-20);
    border-color: var(--biz-primary-20);
  }

  @media (min-width: 700px) {
    font-size: 18px;
  }
}

.btn-primary {
  &:hover {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border-color: #000000 !important;
  }

  &:active {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }

  &:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }
}

.btn-verify {
  background-color: var(--biz-brand-button);
  border-color: var(--biz-brand-button);

  &:hover {
    background-color: var(--biz-brand-button);
    border-color: var(--biz-brand-button);
  }

  &:disabled {
    background-color: var(--biz-primary-20);
    border-color: var(--biz-primary-20);
    color: #ffffff;
  }
}
</style>
